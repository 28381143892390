import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FWBanner from '../../components/fullWidthBanner';
import FWTextBanner from '../../components/fullWidthTextBanner';
import FWCard from '../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    promoHeader: {
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 500,
        letterSpacing: '-0.5px',
        fontSize: '4rem',
        lineHeight: '6rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            lineHeight: '3rem',
        }
    },
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
}))

export default function OurValues(props) {
    const classes = useStyles()
    const locale = 'tr-TR'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='Sürdürülebilirlik' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWBanner imgName='sustainability-ana-banner.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner bg title='Hepimiz, dünyamıza karşı büyük bir sorumluluk taşıyoruz. ' subtitle='Dünyamızı daha iyi hale getirmek hepimizin görevi olmalı. Biz de bu konuda sorumluluğumuzu üstlenip; çalışmalarımızı daha iyi, daha sağlıklı, daha sürdürülebilir bir dünya için gerçekleştiriyoruz.' />

                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Dünyamızı güzelleştirelim' subtitle='Dört ayaklı dostlarımız, bizim hayatımızı daha güzel hale getirirken; biz de hem kendimiz hem de onlar için dünyayı güzelleştirmeyi amaçlıyoruz.' image='sustainability-dunyamizi-guzellestirelim.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Her şey geleceğimiz için' subtitle='Sürdürülebilirlik, geleceğimiz için daha sağlıklı bir ortam sunmaktan gelir. Bu temele bağlı kalarak, planlı bir şekilde adımlarımızı atıyoruz.' image='sustainability-hersey-gelecegimiz-icin.jpg' position='right' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Sorumlu üretim anlayışı' subtitle='Üretim süreçlerimiz, sürdürülebilir standartları karşılayacak şekilde koordine edilmektedir.' image='sustainability-sorumlu-uretim.jpg' />
                </Grid>

            </Grid>
        </Layout>
    )

}


